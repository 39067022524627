import React, { useContext, useEffect } from 'react';

import { WebfontsContext } from './woff-context';

export const useFontLoader = (font) => {
  const { addFont, addFonts } = useContext(WebfontsContext);

  useEffect(() => {
    if (Array.isArray(font)) {
      addFonts(font);
    } else if (font) {
      addFonts([font]);
    }
  }, [font, addFont, addFonts]);
};

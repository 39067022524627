import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { Global, css } from '@emotion/core';

import { storage } from './storage';

export const WebFont = ({ font, onLoaded }) => {
  const [ready, setFontReady] = useState();

  const fontId = font._id.replace('drafts.', '');

  useEffect(async () => {
    const { data, error } = await storage.from('f5f').getPublicUrl(`${fontId}.woff2`);

    const webFont = new FontFace(font._id, `url(${data.publicUrl})`, {
      display: 'block',
    });
    document.fonts.add(webFont);
    webFont.load();
    webFont.loaded.then(() => {
      onLoaded(font._id);
      setFontReady(true);
    });
  }, []);

  const webfontloaderClass = font._id.trim().replace(/-/g, '');

  return (
    <>
      {ready && (
        <Global
          styles={css`
            .txt-${webfontloaderClass} {
              transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
              opacity: 1 !important;
            }
          `}
        />
      )}
    </>
  );
};

/* eslint-disable */

const mono = {
  ['100']: '#FFFFFF',
  ['90']: '#f0f0f0',
  ['80']: '#E3E3E3',
  ['70']: '#C8c8c8',
  ['60']: '#A6A6A6',
  ['50']: '#919191',
  ['40']: '#7D7D7D',
  ['30']: '#6f6f6f',
  ['20']: '#383838',
  ['10']: '#2a2a2a',
  ['5']: '#101010',
  ['0']: '#000000',
};

// const blue = {
//   ['100']: '#39E1FF',
//   ['0']: '#083AD7',
// };

const bgLight = {
  ['0']: mono['100'],
  ['1']: mono['90'],
  ['3']: 'rgba(0,0,0,0.7)',
  ['4']: mono['0'],
};

const txtLight = {
  ['1']: mono['0'],
  ['2']: mono['20'],
  ['3']: mono['30'],
  ['4']: mono['100'],
  ['5']: mono['70'],
};

const bgDark = {
  ['0']: mono['5'],
  ['1']: mono['0'],
  ['3']: 'rgba(0,0,0,0.8)',
  ['4']: mono['100'],
};

const txtDark = {
  ['1']: mono['100'],
  ['2']: mono['80'],
  ['3']: mono['60'],
  ['4']: mono['0'],
  ['5']: mono['20'],
};

export const colors = {
  mono,
  // blue,
  // green,
  // coral,
  // red,
  // yellow,
  bg: bgLight,
  txt: txtLight,
  // accents: accent,
  accent: '#f6797d',
  accentHover: '#f3dd95',
  background: mono['100'],
  text: mono['0'],
  textInv: mono['100'],
  line: mono['30'],

  button: {
    primary: {
      bg: mono['0'],
      text: mono['100'],
      bg_hover: mono['0'],
      text_hover: mono['100'],
    },
    invert: {
      bg: mono['100'],
      text: mono['0'],
      bg_hover: mono['0'],
      text_hover: mono['100'],
    },
    ghost: {
      bg: 'transparent',
      text: mono['0'],
      bg_hover: mono['80'],
      text_hover: mono['0'],
    },
    home_block: {
      bg: 'transparent',
      bg_hover: mono['90'],
      text_hover: ['100'],
    },
    link_popover: {
      text: mono['100'],
      text_hover: mono['70'],
    },
  },

  modes: {
    dark: {
      bg: bgDark,
      txt: txtDark,
      text: mono['100'],
      textInv: mono['0'],
      background: mono['5'],
      line: mono['20'],
      accent: '#f3dd95',
      accentHover: '#f6797d',
      button: {
        primary: {
          bg: mono['100'],
          text: mono['0'],
          bg_hover: mono['100'],
          text_hover: mono['0'],
        },
        invert: {
          bg: mono['0'],
          text: mono['100'],
          bg_hover: mono['100'],
          text_hover: mono['0'],
        },
        ghost: {
          bg: 'transparent',
          text: mono['100'],
          bg_hover: mono['10'],
          text_hover: mono['100'],
        },
        home_block: {
          bg: 'transparent',
          bg_hover: mono['10'],
          text_hover: mono['100'],
        },
        link_popover: {
          text: mono['0'],
          text_hover: mono['40'],
        },
      },
    },
  },
};

/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { motion } from 'framer-motion';
import { VisuallyHidden } from '../visually-hidden';

export const ThemeSwitch = ({ ...rest }) => {
  const [colorMode, setColorMode] = useColorMode();
  const [hover, setHover] = useState(false);

  const handleHover = () => setHover(() => true);
  const handleOut = () => setHover(() => false);

  return (
    <motion.button
      onMouseOver={handleHover}
      onMouseOut={handleOut}
      onClick={(e) => {
        setHover(false);
        setColorMode(colorMode === 'default' ? 'dark' : 'default');
      }}
      sx={{ color: 'text', p: 4 }}
      initial="out"
      animate={hover ? 'hover' : 'out'}
      {...rest}
    >
      <motion.div
        animate={colorMode == 'dark' ? 'dark' : 'default'}
        variants={{
          default: { rotate: 204.5 },
          dark: { rotate: 24.5 },
        }}
        sx={{
          position: 'relative',
          display: 'block',
          width: ['26px', '26px', '20px'],
          height: ['26px', '26px', '20px'],
        }}
      >
        <div
          sx={{
            border: '2px solid',
            borderColor: 'text',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'block',
            width: '100%',
            height: '100%',
            borderRadius: '100%',
            zIndex: 1,
          }}
        />
        <div
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'block',
            width: '100%',
            height: '100%',
            zIndex: 0,
            borderRadius: '100%',
            overflow: 'hidden',
          }}
        >
          <motion.div
            initial="out"
            animate={hover ? 'hover' : 'out'}
            variants={{
              hover: { x: '0%' },
              out: { x: '50%' },
            }}
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '120%',
              height: '100%',
              backgroundColor: 'text',
            }}
          />
        </div>
      </motion.div>
      <VisuallyHidden as="span">Toggle theme</VisuallyHidden>
    </motion.button>
  );
};

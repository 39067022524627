export const slider = {
  height: 1,
  bg: 'txt.3',
  thumb: {
    width: 7,
    height: 7,
  },
  '&:focus': {
    color: 'accent',
    bg: 'txt.2',
  },
};

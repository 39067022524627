/** @jsx jsx */
import { Text, Flex, jsx } from 'theme-ui';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cart } from '../icons';
import { Anchor } from '../components/link';
import { Tally } from '../components/typeface-shop/tally';

export const CartToast = ({ show = false, items = 0, ...rest }) => {
  return (
    <AnimatePresence initial={false}>
      {show ? (
        <motion.div
          {...rest}
          initial="closed"
          animate="open"
          exit="closed"
          variants={{
            open: { x: '-100%' },
            closed: { x: '0' },
          }}
          transition={{ type: 'spring', stiffness: 400, damping: 30 }}
          sx={{
            position: 'fixed',
            bottom: [9],
            left: '100%',
            width: ['300px', '380px'],
            bg: 'bg.4',
            color: 'txt.4',
            zIndex: 50,
            pt: [7],
            px: [9],
            pb: [8],
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Flex sx={{ flex: 1, flexDirection: 'column' }}>
              <Anchor to="/cart" sx={{ variant: 'buttons.link_popover' }}>
                <Text as="h4" variant="h3">
                  Added to cart
                </Text>
                View Cart
              </Anchor>
            </Flex>
            <Anchor
              to="/cart"
              sx={{
                variant: 'buttons.link_popover',
                flex: '0 auto',
                '& [data-child="tally"]': { opacity: 0.5 },
              }}
            >
              <Flex sx={{ flex: '0 auto', alignItems: 'center' }}>
                <Cart sx={{ width: 10 }} />
                <Tally
                  data-child="tally"
                  tally={items}
                  sx={{ right: '100%', left: 0, marginLeft: '-10px', marginTop: '-10px' }}
                />
              </Flex>
            </Anchor>
          </Flex>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

/** @jsx jsx */
import { jsx, Container, Flex, Text } from 'theme-ui';
import { useMemo } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { VisuallyHidden } from '../visually-hidden';
import { Grid, Col, Stack } from '../grid';
import { Anchor } from '../link';
import { Twitter, Facebook, Insta, LinkedIn } from '../../icons';

export const Footer = ({ className }) => {
  const { settings } = useStaticQuery(settingsQuery);

  const date = useMemo(() => {
    const d = new Date();
    return d.getFullYear();
  }, []);

  const navFonts = [
    ...settings.nav_fonts_nursery.filter((font) => font.published),
    ...settings.nav_fonts.filter((font) => font.published),
    ...settings.nav_fonts.filter((font) => font.title.includes('Trial')),
    {
      slug: { current: 'trial-fonts' },
      title: ' Trial Fonts',
    },
  ];

  const half = Math.ceil(navFonts.length / 2);
  const firstHalf = navFonts.slice(0, half);
  const secondHalf = navFonts.slice(half);

  return (
    <Container
      as="footer"
      sx={{
        borderTop: '1px solid',
        borderColor: 'line',
        bg: 'bg.1',
      }}
      aria-labelledby="footer-title"
    >
      <VisuallyHidden id="footer-title" as="h2">
        Footer
      </VisuallyHidden>
      <Grid columns={[2, 2, 8, 12]} gap={false} gapY={10} sx={{ py: [8, 10] }}>
        <Col span={[2, 1, 4, 4]}>
          <Stack gap={[5]}>
            <Text as="h3" variant="small" sx={{ fontWeight: 'bold' }}>
              Fonts
            </Text>
            <div
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr 1fr', '1fr 1fr'],
              }}
            >
              <Stack sx={{ width: '16ch', justifyItems: 'start' }}>
                {firstHalf.map((link) => (
                  <Link
                    sx={{ width: 'max-content', variant: 'buttons.small_link' }}
                    to={`/${link._type === 'nursery' ? 'nursery' : 'type'}/${link.slug.current}`}
                    key={link.slug.current}
                  >
                    {link.title}
                  </Link>
                ))}
              </Stack>
              <Stack sx={{ width: '16ch', justifyItems: 'start' }}>
                {secondHalf.map((link) => (
                  <Link
                    sx={{ width: 'max-content', variant: 'buttons.small_link' }}
                    to={`/${link._type === 'nursery' ? 'nursery' : 'type'}/${link.slug.current}`}
                    key={link.slug.current}
                  >
                    {link.title}
                  </Link>
                ))}
              </Stack>
            </div>
          </Stack>
        </Col>
        <Col span={[1, 1, 2, 2]}>
          <Stack gap={[5]}>
            <Text as="h3" variant="small" sx={{ fontWeight: 'bold' }}>
              Find us online
            </Text>
            <Stack gap={[0]}>
              {settings.footer_social_links.map((link) => (
                <Anchor
                  sx={{ variant: 'buttons.small_link' }}
                  to={`${link.href}`}
                  target="new"
                  key={link.href}
                >
                  {link.label}
                </Anchor>
              ))}
            </Stack>
          </Stack>
        </Col>
        <Col span={[1, 1, 2, 2]}>
          <Stack gap={[5]}>
            <Text as="h3" variant="small" sx={{ fontWeight: 'bold' }}>
              Links
            </Text>
            <Stack gap={[0]} sx={{ justifyItems: 'flex-start' }}>
              {settings.footer_nav_pages.map((link) => (
                <Link
                  sx={{ width: 'max-content', variant: 'buttons.small_link' }}
                  to={`/${link.slug.current}`}
                  key={link.slug.current}
                >
                  {link.title}
                </Link>
              ))}
            </Stack>
          </Stack>
        </Col>

        <Col start={[1, 1, 1, 9]} span={[2, 3, 4, 4]}>
          <Stack gap={[5]} sx={{ maxWidth: '32rem' }}>
            <Text as="p" variant="small" sx={{ fontWeight: 'bold' }}>
              ©{date} Foundry5 Ltd. All rights reserved.
            </Text>
            <Text as="p" sx={{ fontSize: [2, 1, 1, 1] }}>
              Foundry5 is a limited company registered in England and Wales. Registered number:
              12225264. Registered office: 22 Dykes Bower Court, 301 Arbury road, CB4 2JZ,
              Cambridge.
            </Text>
          </Stack>
        </Col>
      </Grid>
      <Flex sx={{ justifyContent: 'flex-end' }}>
        <Text as="p" sx={{ fontSize: '10px', textTransform: 'uppercase', color: 'txt.1', mb: 2 }}>
          <OutboundLink target="new" href="https://tol.is" sx={{ textDecoration: 'none' }}>
            tol.is
          </OutboundLink>{' '}
        </Text>
      </Flex>
      {/* <Col span={[1, 12]}>
          <Grid columns={[1, 12]} gap={true}>
            <Col span={6}>
              <Text as="p" variant="tiny" sx={{ fontWeight: 'bold' }}>
                ©{date} Foundry5 Ltd. All rights reserved.
              </Text>
            </Col>
            <Col start={8} span={5}>
              <Text as="p" variant="tiny">
                Foundry5 is a limited company registered in England and Wales. Registered number:
                12225264. Registered office: 3 Beacon Rise, 160 Newmarket Road, CB5 8AX, Cambridge,
                Cambridgeshire.
              </Text>
            </Col>
          </Grid>
        </Col> */}
    </Container>
  );
};

// const Social = () => {
//   return (
//     <Stack gap={[7]}>
//       <Text as="p" variant="footer" sx={{ fontWeight: 'bold' }}>
//         Find us on social
//       </Text>
//       <Flex>
//         <Anchor
//           external
//           to="https://twitter.com/foundryfive"
//           variant={'buttons.link'}
//           sx={{ mr: 5, width: 10 }}
//         >
//           <Twitter />
//         </Anchor>

//         <Anchor
//           external
//           to="https://www.instagram.com/foundry5type/"
//           variant={'buttons.link'}
//           sx={{ mx: 5, width: 10 }}
//         >
//           <Insta />
//         </Anchor>
//         <Anchor
//           external
//           to="https://www.linkedin.com/company/foundry5"
//           variant={'buttons.link'}
//           sx={{ ml: 5, width: 10 }}
//         >
//           <LinkedIn />
//         </Anchor>
//       </Flex>
//     </Stack>
//   );
// };

const settingsQuery = graphql`
  query {
    settings: sanitySiteSettings {
      _id

      nav_fonts_nursery {
        id
        title
        _type
        published
        slug {
          current
        }
      }
      nav_fonts {
        ... on SanityTypeface {
          id
          title
          _type
          published
          slug {
            current
          }
        }
        ... on SanityPageTrials {
          id
          title
          slug {
            current
          }
        }
      }

      footer_nav_pages {
        ... on SanityPageAbout {
          id
          title
          slug {
            current
          }
        }
        ... on SanityPage {
          id
          title
          slug {
            current
          }
        }
      }
      footer_social_links {
        label
        href
      }
    }
  }
`;

export default Footer;

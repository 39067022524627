import { StorageClient } from '@supabase/storage-js';

const STORAGE_URL = 'https://xvhttfdxjhesabcqkrig.supabase.co/storage/v1';
const SERVICE_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh2aHR0ZmR4amhlc2FiY3FrcmlnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE1OTcwNzAsImV4cCI6MjAxNzE3MzA3MH0.Dnxi0bJDjNWw8Q1bDaTbu8F6W9pkG9Wen0c10Ny3SWg';

export const storage = new StorageClient(STORAGE_URL, {
  apikey: SERVICE_KEY,
  Authorization: `Bearer ${SERVICE_KEY}`,
});

import * as React from 'react';
import useMedia from 'use-media';

export const Logo = (props) => {
  const isSmall = useMedia({ maxWidth: '68.75rem' });
  return isSmall ? (
    <svg
      width="52px"
      height="33px"
      viewBox="0 0 52 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M51.42,21.573 C51.42,28.634 46.655,32.923 37.298,32.923 C27.118,32.923 22.743,29.11 22.873,22.135 L23.263,20.273 L35.262,20.273 L35.262,24.129 L37.861,24.129 L37.861,17.674 L23.826,17.674 L27.551,7.10542736e-15 L49.558,7.10542736e-15 L49.558,9.79 L36.691,9.79 L36.259,12.388 L39.464,12.388 C47.738,12.388 51.42,15.031 51.42,21.573 L51.42,21.573 Z"
          id="Path"
        ></path>
        <polygon
          id="Path"
          points="12.866 11.739 12.866 14.339 21.876 14.339 19.84 24.085 12.866 24.085 12.866 32.359 0 32.359 0 2.13162821e-14 24.909 2.13162821e-14 22.44 11.739"
        ></polygon>
      </g>
    </svg>
  ) : (
    <svg width="156" height="23" viewBox="0 0 156 23" role="presentation" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M8.565 8.19v1.731h6.547v6.49H8.565v5.508H0V.375h15.919V8.19zM26.099 16.266h1.875V6.028h-1.875v10.238zm-9.025-5.047C17.074 3.663 20.734 0 27.052 0c6.287 0 9.95 3.663 9.95 11.219 0 7.412-3.663 11.075-9.95 11.075-6.317 0-9.978-3.663-9.978-11.075zM38.299 13.094V.373h8.594v15.892h1.73V.374h8.595v12.719c0 6.316-3.231 9.2-9.517 9.2-6.172 0-9.402-2.884-9.402-9.2M59.092.375h8.22l2.768 7.24h.98V.374h6.922V21.92h-8.22l-2.768-7.297h-.98v7.297h-6.922zM87.788 15.892h1.73V6.403h-1.73v9.489zM79.712.375h8.45c6.288 0 10.383 3.375 10.383 10.844 0 7.325-4.095 10.7-10.383 10.7h-8.45V.375zM108.062 11.277h1.73V6.403h-1.73v4.874zM99.986.375h9.748c6.288 0 8.797 2.365 8.797 7.412 0 4.384-2.134 5.364-4.067 6.028v1.211h4.96v6.893h-4.47c-4.21 0-5.335-1.355-5.335-5.854h-1.558v5.854h-8.075V.375z" />
        <path d="M139.324.375L133.7 15.978l-2.567 5.941h-10.036l2.653-6.95L117.636.375h10.267v8.911h1.73V.376zM155.762 14.738c0 4.701-3.172 7.556-9.401 7.556-6.778 0-9.518-2.538-9.518-7.181v-1.241h8.162v2.567h1.73v-4.296h-9.72l4.24-11.768h13.267v6.518h-7.816l-.548 1.73h1.644c5.508 0 7.96 1.76 7.96 6.115" />
      </g>
    </svg>
  );
};

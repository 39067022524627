import { text, fonts, fontSizes, lineHeights, fontWeights } from './theme/typography';
import { borders } from './theme/border';
import { colors } from './theme/colors';
import { space, sizes } from './theme/space';
import * as root from './theme/root';
import { breakpoints } from './theme/breakpoints';
import * as containers from './theme/layout';
import * as forms from './theme/forms';
import * as links from './theme/links';
import * as buttons from './theme/buttons';
import * as grid from './theme/grid';

export const base = {
  initialColorModeName: 'dark',
  breakpoints,
  space,
  sizes,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  colors,
  borders,
  forms,
  gradients: {
    primary: `linear-gradient(to right, #c5ffde , #f3dd95, #f6797d)`,
    secondary: `linear-gradient(to right, #f3dd95, #f6797d)`,
    tertiary: `linear-gradient(to right, #c5ffde , #f3dd95)`,
  },
  text,
  styles: {
    ...root,
  },
  buttons: {
    ...buttons,
  },
  links: {
    ...links,
  },
  layout: {
    ...containers,
  },
  grid: {
    ...grid,
  },
};

export default base;

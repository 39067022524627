/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

export const Anchor = ({ variant, to, email, external, children, ...rest }) => {
  //

  return email ? (
    <a href={to} sx={{ variant: variant }} {...rest}>
      {children}
    </a>
  ) : !external ? (
    <Link sx={{ variant: variant }} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <a href={to} target="_blank" rel="noreferrer" sx={{ variant: variant }} {...rest}>
      {children}
    </a>
  );
};

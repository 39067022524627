import { canUseDOM } from './platform';

const cookieNames = {
  privacyPolicy: 'foundry-five-privacy-policy',
};

const checkCookieHasValue = (cookieName, value) => {
  // Default to false during SSR. Doesn't matter too much, will be checked again
  // at runtime
  if (!canUseDOM()) {
    return false;
  }

  return document.cookie.split(';').filter((item) => item.includes(`${cookieName}=${value}`))
    .length;
};

const setCookie = (cookieName, value, daysUntilExpires = 365) => {
  const d = new Date();
  d.setTime(d.getTime() + daysUntilExpires * 24 * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${value};expires=${d.toUTCString()};path=/`;
};

export const alreadyAcceptedPrivacyPolicy = () => {
  return checkCookieHasValue(cookieNames.privacyPolicy, 'true');
};

export const recordPrivacyPolicyAcceptance = () => {
  if (alreadyAcceptedPrivacyPolicy()) {
    return;
  }

  setCookie(cookieNames.privacyPolicy, 'true');
};

import React, { useMemo } from 'react';
import { Box } from 'theme-ui';

import { gutter } from '../../gatsby-plugin-theme-ui/theme/grid';

export const Stack = ({ gap, variant = null, ...rest }) => {
  const gridGap =
    gap === true
      ? gutter
      : Array.isArray(gap)
      ? gap.map((g, i) => (g === true ? gutter[i] : g))
      : gap;

  return (
    <Box
      variant={`grid.stack.${variant}`}
      sx={{
        gridGap: gridGap,
        display: 'grid',
        alignContent: 'flex-start',
        gridTemplateColumns: 'repeat(1, minmax(0,1fr))',
      }}
      {...rest}
    />
  );
};

export const Grid = ({ columns = 6, variant = null, gap, gapY, ...rest }) => {
  const cols = useMemo(() => {
    if (typeof columns === 'number') {
      return `repeat(${columns}, minmax(0,1fr))`;
    }

    return columns.map((c) => `repeat(${c}, minmax(0,1fr))`);
  }, [columns]);

  const gapSx = {};

  const gridGap =
    gap === true
      ? gutter
      : Array.isArray(gap)
      ? gap.map((g, i) => (g === true ? gutter[i] : g))
      : gap;

  const gridRowGap =
    gapY === true
      ? gutter
      : Array.isArray(gapY)
      ? gapY.map((g, i) => (g === true ? gutter[i] : g))
      : gapY;

  return (
    <Box
      variant={`grid.${variant}`}
      sx={{
        columnGap: gridGap,
        rowGap: gridRowGap,
        display: 'grid',
        gridTemplateColumns: cols,
      }}
      {...rest}
    />
  );
};

export const Row = ({ ...rest }) => {
  return <Box variant={`grid.row`} {...rest} />;
};

export const Col = ({ start, span = 1, row, ...rest }) => {
  const getColumnValue = (start, span) => {
    if (span === -1) {
      return `${start} / ${span}`;
    } else {
      return `${start} / span ${span}`;
    }
  };

  const column = useMemo(() => {
    if ((start === undefined || start === null) && (span === undefined || span === null)) {
      return [];
    }

    if (start === undefined || start === null) {
      return Array.isArray(span) ? span.map((v) => `span ${v}`) : `span ${span}`;
    }

    if (typeof span === 'number' && typeof start === 'number') {
      return [getColumnValue(start, span)];
    }

    if (Array.isArray(span) && typeof start === 'number') {
      return span.map((v) => getColumnValue(start, v));
    }

    if (Array.isArray(start) && typeof span === 'number') {
      return start.map((v) => getColumnValue(v, span));
    }

    if (Array.isArray(start) && Array.isArray(span)) {
      if (start.length !== span.length)
        throw new Error('Col start/span props must be arrays of equal length');
      return start.map((v, i) => getColumnValue(v, span[i]));
    }

    return [null];
  }, [start, span]);

  return (
    <Box
      sx={{
        gridColumn: column,
        gridRow: row,
      }}
      {...rest}
    />
  );
};

export default { Grid, Col };

import React, { useMemo, useState, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useStaticQuery, graphql } from 'gatsby';

import { WebfontsContext } from './woff-context';
import { WebFont } from './web-font';

export const WebfontProvider = observer((props) => {
  const data = useStaticQuery(fontsQuery);

  const library = useMemo(() => {
    return data.allSanityFontOpenType.nodes.reduce((res, font) => {
      res[font._id] = font;
      return res;
    }, {});
  }, [data]);

  // mobx store
  const store = useLocalStore(() => ({
    fonts: {},
    loaded: {},
  }));

  const handleFontLoaded = useCallback(
    (fontId) => {
      store.loaded[fontId] = true;
    },
    [store]
  );

  const addFont = useCallback(
    (fontId) => {
      if (!store.fonts[fontId]) {
        store.fonts[fontId] = library[fontId];
      }
    },
    [store]
  );

  const addFonts = useCallback(
    (fontList) => {
      fontList.forEach((fontId) => {
        if (!store.fonts[fontId]) {
          store.fonts[fontId] = library[fontId];
        }
      });
    },
    [store]
  );

  return (
    <WebfontsContext.Provider
      value={{
        store: store,
        addFont,
        addFonts,
      }}
    >
      {Object.values(store.fonts).map((font) => (
        <WebFont key={font._id} font={font} onLoaded={handleFontLoaded} />
      ))}

      {props.children}
    </WebfontsContext.Provider>
  );
});

export default function Header() {
  return (
    <header>
      <h1>{data.site.siteMetadata.title}</h1>
    </header>
  );
}

const fontsQuery = graphql`
  query {
    allSanityFontOpenType {
      nodes {
        _id
        italic
        name
        title
        webfont
        weight
      }
    }
  }
`;

/** @jsx jsx */
import { Text, Flex, jsx } from 'theme-ui';
import React, { useRef, useEffect, useState } from 'react';

import { AnimatePresence, motion, useMotionValue, useTransform, animate } from 'framer-motion';

export const Tally = ({ tally = 0, size = 'sm', ...rest }) => {
  return (
    <AnimatePresence initial={false}>
      {tally > 0 ? (
        <motion.div
          {...rest}
          key="tally"
          initial="closed"
          animate="open"
          exit="closed"
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            left: '100%',
            marginLeft: '0.3em',
            top: 0,
            borderRadius: '50%',
            backgroundColor: 'accent',
            width: '2em',
            height: '2em',
            lineHeight: '1.85em',
            fontSize: '12px',
            color: 'txt.4',
            textAlign: 'center',
            fontWeight: '700',
          }}
          variants={{
            open: { scale: 1, opacity: 1 },
            closed: { scale: 0, opacity: 0 },
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        >
          <AnimatePresence>
            <motion.div
              sx={{ position: 'absolute', width: '100%', textAlign: 'center' }}
              key={`tally_${tally}`}
              initial={{ y: '-2em' }}
              animate={{ y: '0em' }}
              exit={{ y: '2em' }}
            >
              {tally}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

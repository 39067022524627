export const root = {
  fontFamily: 'sans',
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: [1, 1, 2, 2],
  lineHeight: 1.675,
};

export const button = {
  fontSize: 1,
  backgroundColor: 'transparent',
  appearance: 'none',
  textAlign: 'left',
  lineHeight: 'inherit',
  textDecoration: 'none',
  fontWeight: 400,
  color: 'text',
  m: 0,
  p: 0,
  height: 'auto',
  border: 0,
  borderRadius: 0,
};

export const ul = {
  listStyle: 'none',
};

export const a = {
  textDecoration: 'none',
};

export function canUseDOM() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

export const createNodeWithText = text => {
  const node = document.createElement('pre');
  node.style.width = '1px';
  node.style.height = '1px';
  node.style.position = 'fixed';
  node.style.top = '5px';
  node.textContent = text;
  return node;
};

export const selectNodeContents = node => {
  const selection = getSelection();
  selection.removeAllRanges();
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.addRange(range);

  return selection;
};

// Inspired by Github's open source implementation of copy-to-clipboard
// https://github.com/github/clipboard-copy-element/blob/master/src/clipboard.js
export const copyTextToClipboard = text => {
  // Use the cleaner navigator.clipboard API if available
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }

  // Otherwise, fallback to a hackier but widely-supported method.
  // Create a temporary DOM node, select it's contents, copy, then clean up.
  const node = createNodeWithText(text);
  document.body.appendChild(node);
  const selection = selectNodeContents(node);
  document.execCommand('copy');
  selection.removeAllRanges();
  document.body.removeChild(node);
};

export const encodeObjectAsUrlParams = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

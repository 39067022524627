export const primary = {
  position: 'relative',
  backgroundColor: 'button.primary.bg',
  color: 'button.primary.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [2],
  py: [6, 6, 7],
  px: [7, 7, 10],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',

  ':hover, :focus': {
    backgroundColor: 'button.primary.bg_hover',
    color: 'button.primary.text_hover',
  },
  ':focus-visible': {
    outlineColor: 'button.primary.bg_hover',
    outlineOffset: '4px',
  },
};

export const invert = {
  position: 'relative',
  backgroundColor: 'button.invert.bg',
  color: 'button.invert.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  fontSize: [2, 2, 3, 3, 4],
  py: [5],
  px: [6],

  ':hover, :focus': {
    backgroundColor: 'button.invert.bg_hover',
    color: 'button.invert.text_hover',
  },
  ':focus-visible': {
    outlineColor: 'button.invert.bg_hover',
    outlineOffset: '4px',
  },
};

export const secondary = {
  position: 'relative',
  backgroundColor: 'button.primary.bg',
  color: 'button.primary.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [1],
  py: [5],
  px: [6],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    backgroundColor: 'button.primary.text_hover',
    color: 'button.primary.bg_hover',
  },
  ':focus-visible': {
    backgroundColor: 'button.primary.text_hover',
    outlineOffset: '4px',
  },
};

export const invert_small = {
  position: 'relative',
  backgroundColor: 'button.invert.bg',
  color: 'button.invert.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [2],
  py: [4, 5, 6],
  px: [7, 7, 10],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    backgroundColor: 'button.invert.bg_hover',
    color: 'button.invert.text_hover',
  },
  ':focus-visible': {
    outlineColor: 'button.invert.bg_hover',
    outlineOffset: '4px',
  },
};

export const primary_small = {
  position: 'relative',
  backgroundColor: 'button.primary.bg',
  color: 'button.primary.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [2],
  py: [6, 6, 7],
  px: [7, 7, 10],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    backgroundColor: 'button.primary.bg_hover',
    color: 'button.primary.text_hover',
  },
  ':focus-visible': {
    outlineColor: 'button.primary.bg_hover',
    outlineOffset: '4px',
  },
};

export const invert_tiny = {
  position: 'relative',
  backgroundColor: 'button.invert.bg',
  color: 'button.invert.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [1],
  py: [2, 3, 3],
  px: [5, 5, 6],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    backgroundColor: 'button.invert.bg_hover',
    color: 'button.invert.text_hover',
  },
  ':focus-visible': {
    outlineColor: 'button.invert.bg_hover',
    outlineOffset: '4px',
  },
};

export const tiny_text = {
  position: 'relative',
  color: 'button.invert.bg',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [1],
  py: [2, 3, 3],
  p: [0],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'button.invert.bg_hover',
  },
  ':focus-visible': {
    outlineColor: 'button.invert.bg_hover',
    outlineOffset: '4px',
  },
};

export const link = {
  position: 'relative',
  color: 'text',
  appearance: 'none',
  textDecoration: 'underline',
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'accent',
  },
  ':focus-visible': {
    outlineColor: 'accent',
    outlineOffset: '4px',
  },
};

export const link_popover = {
  flex: 0,
  position: 'relative',
  color: 'button.link_popover.text',
  borderRadius: 0,
  appearance: 'none',
  textDecoration: 'none',
  fontSize: [1],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'button.link_popover.text_hover',
  },
  ':focus-visible': {
    outlineOffset: '4px',
  },
};

export const link_invert = {
  flex: 0,
  position: 'relative',
  color: 'button.primary.text',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: [1],
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'button.primary.bg_hover',
  },
  ':focus-visible': {
    outlineOffset: '4px',
  },
};

export const header_nav_link = {
  position: 'relative',
  color: 'txt.1',
  appearance: 'none',
  textDecoration: 'none',
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    outline: 'none',
    color: 'accent',
    textDecoration: 'underline',
    textUndelineOffset: '3px',
  },
  '&:focus-visible': {
    outline: 'none !important',
  },
};

export const nav_link = {
  position: 'relative',
  color: 'mono.100',
  appearance: 'none',
  textDecoration: 'none',
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'accent',
  },
  ':focus-visible': {
    outlineColor: 'accent',
    outlineOffset: '4px',
  },
};

export const small_link = {
  position: 'relative',
  color: 'text',
  appearance: 'none',
  fontSize: [0, 0, 1, 1, 1],
  textDecoration: 'none',
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'accent',
  },
  ':focus-visible': {
    outlineColor: 'accent',
    outlineOffset: '4px',
  },
  ':disabled': {
    opacity: 0.4,
    pointerEvents: 'none',
    ':hover, :focus': {
      color: 'text',
    },
    ':focus-visible': {
      outline: 'none',
    },
  },
};

export const medium_link = {
  position: 'relative',
  color: 'text',
  appearance: 'none',
  fontWeight: 'bold',
  fontSize: [2, 2, 6, 6, 6],
  textDecoration: 'none',
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    color: 'accent',
  },
  ':focus-visible': {
    outlineColor: 'accent',
    outlineOffset: '4px',
  },
  ':disabled': {
    opacity: 0.4,
    pointerEvents: 'none',
    ':hover, :focus': {
      color: 'text',
    },
    ':focus-visible': {
      outline: 'none',
    },
  },
};

export const ghost = {
  position: 'relative',
  backgroundColor: 'button.ghost.bg',
  color: 'button.ghost.text',
  textDecoration: 'none',
  display: 'block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  fontSize: [2],
  py: [6, 6, 7],
  px: [7, 7, 10],
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':hover, :focus': {
    backgroundColor: 'button.ghost.bg_hover',
    color: 'button.ghost.text_hover',
  },
  ':focus-visible': {
    outlineColor: 'txt.1',
    outlineOffset: '4px',
  },
};

export const ghost_promo = {
  position: 'relative',
  backgroundColor: 'rgba(255,255,255,0)',
  textDecoration: 'none',
  display: 'inine-block',
  borderRadius: 0,
  appearance: 'none',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: [2],
  py: [6],
  px: [7, 7, 10],
  borderRadius: 0,
  transition: 'all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  ':focus-visible': {
    outlineColor: 'white',
    outlineOffset: '4px',
  },
};

export const tester = {
  buttonDropdown: {
    position: 'relative',
    fontSize: [1, 1, 2, 2],
    backgroundColor: 'transparent',
    appearance: 'none',
    textAlign: 'left',
    lineHeight: 'inherit',
    textDecoration: 'none',
    fontWeight: 400,
    color: 'inherit',
    display: 'flex',
    flex: 1,
    m: 0,
    p: 0,
    pr: 0,
    py: 0,
    border: 0,
    borderRadius: 0,
  },
  fontStyle: {
    fontSize: [2, 2, 3, 4],
    backgroundColor: 'transparent',
    appearance: 'none',
    textAlign: 'left',
    lineHeight: 'inherit',
    textDecoration: 'none',
    color: 'text',
    m: 0,
    p: 0,
    border: 0,
    borderRadius: 0,
    ':hover, :focus': {
      color: 'accent',
    },
    ':focus-visible': {
      outlineColor: 'accent',
      outlineOffset: '4px',
    },
  },
};

/** @jsx jsx */
import { Button, Box, Flex, jsx, Text } from 'theme-ui';
import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Anchor } from './link';

//fixed bottom-0 inset-x-0 z-50

export const GDPRPopup = ({ onAcceptPrivacyPolicy }) => {
  return (
    <div
      sx={{
        backgroundColor: 'bg.0',
        position: 'fixed',
        bottom: 10,
        zIndex: 50,
        width: '100%',
        maxWidth: '50rem',
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0px 0px 14px #888888',
      }}
      className="bg-white shadow-md p-sp20 flex flex-col md:flex-row items-center"
    >
      <Flex sx={{ padding: [8, 10], color: 'txt.0', flexDirection: 'column' }}>
        <Text as="p">
          This site uses cookies and similar technologies to store information on your computer or
          device. By continuing to use this site, you agree to the placement of these cookies and
          similar technologies. Read our updated{' '}
          <Anchor to="/terms-and-privacy">Privacy & Cookies Notice</Anchor> to learn more.
        </Text>
        <div sx={{ flex: '0 0 auto', marginTop: 10 }}>
          <Button tabIndex={0} variant="primary_small" onClick={onAcceptPrivacyPolicy}>
            ACCEPT
          </Button>
        </div>
      </Flex>
    </div>
  );
};

import React, { useRef, useMemo, useEffect, createContext, useContext, useState } from 'react';

import createPersistedState from 'use-persisted-state';
export const useProductsVisible = createPersistedState('shop-products-visible');

import { CartToast } from './toast';

export const CartContext = createContext({
  ready: false,
});

export const useTypefaceTotal = (typeface) => {
  const { items } = useContext(CartContext);
  const keys = Object.keys(items);
  const typefaceItems = keys.filter((key) => {
    if (!items[key]) return false;
    return items[key].categories.includes(typeface);
  });

  const totalTypefaceCost = typefaceItems.reduce((res, cur) => {
    res += items[cur].totalPrice;
    return res;
  }, 0);
  return totalTypefaceCost;
};

export const useCartTotal = () => {
  const { items } = useContext(CartContext);
  const keys = Object.keys(items);

  const totalCartCost = keys.reduce((res, cur) => {
    res += items[cur].totalPrice;
    return res;
  }, 0);
  return totalCartCost;
};

export const useCart = () => {
  const { items, ready } = useContext(CartContext);

  return { cart: items, ready };
};

export const useCartLicenceCount = (products) => {
  const { items } = useContext(CartContext);

  const types = ['web', 'desktop', 'app', 'ebook'];

  const counts = types.reduce(
    (res, type) => {
      // const

      const productOfType = products.reduce((res, p) => (res += items[`${p}-${type}`] ? 1 : 0), 0);

      res[type] = productOfType;
      return res;
    },
    {
      web: 0,
      desktop: 0,
      app: 0,
      ebook: 0,
    }
  );

  return counts;
};

export const CartProvider = ({ children }) => {
  const toastTimeout = useRef(0);
  const [ready, setReady] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [items, setItems] = useState({});

  const totalItems = useMemo(() => Object.keys(items).filter(Boolean).length, [items]);

  useEffect(() => {
    if (!showToast) return;
    clearTimeout(toastTimeout.current);

    toastTimeout.current = setTimeout(() => {
      setShowToast(() => false);
    }, 3200);

    return () => {
      clearTimeout(toastTimeout.current);
    };
  }, [showToast, toastTimeout, setShowToast]);

  const initializeItems = async () => {
    try {
      const store = await window.Snipcart.store.getState();
      const storeState = store.cart.items.items.reduce((res, item) => {
        res[item.id] = item;
        return res;
      }, {});
      setItems(() => storeState);
      setReady(() => true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (window.Snipcart) {
      window.Snipcart.ready.then(() => {
        initializeItems();
      });
    }
  }, [setReady]);

  useEffect(() => {
    if (!ready) return;

    window.Snipcart.api.cart.update({
      customFields: [
        {
          name: 'subscribeToNewsletter',
          value: true,
          type: 'boolean',
        },
      ],
    });

    window.Snipcart.events.on('item.added', (cartItem) => {
      setItems((state) => {
        const newState = { ...state, [cartItem.id]: cartItem };
        return newState;
      });
      setShowToast(true);
    });
    window.Snipcart.events.on('item.updated', (cartItem) => {
      setItems((state) => {
        const newState = { ...state, [cartItem.id]: cartItem };
        return newState;
      });
    });
    window.Snipcart.events.on('item.removed', (cartItem) => {
      setItems((state) => {
        const newState = { ...state };
        delete newState[cartItem.id];
        return newState;
      });
    });

    window.Snipcart.events.on('cart.confirmed', () => {
      setItems({});
    });
  }, [ready, toastTimeout]);

  const providerValue = useMemo(() => {
    return { items, ready };
  }, [items, ready]);

  return (
    <>
      <CartToast show={showToast} items={totalItems} />
      <CartContext.Provider value={providerValue}>{children}</CartContext.Provider>
    </>
  );
};

/** @jsx jsx */
import React from 'react';
import { Container, Text, Flex, Box, jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { TextF5 } from '../text-f5';
import { Row } from '../grid';

const Tag = ({ type, children }) => {
  const types = {
    'new-release': { backgroundImage: (t) => t.gradients.secondary, color: 'mono.0' },
    sale: { bg: 'accent', color: 'mono.0' },
    nursery: {
      backgroundImage: (t) => t.gradients.tertiary,
      color: 'mono.0',
    },
  };
  return (
    <div
      data-child="tag"
      sx={{
        ...types[type.toLowerCase().replace(' ', '-')],
        position: 'absolute',
        transform: 'translateX(50%)',
        transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        top: -1,
        right: 0,
        py: 2,
        px: [5],
        textAlign: 'center',
        fontWeight: 700,
      }}
    >
      <Text sx={{ fontSize: '8px', textTransform: 'uppercase' }}>{children}</Text>
    </div>
  );
};

// backgroundImage: (t) => t.gradients.tertiary,
// color: 'mono.0',

export const FontBlock = ({ to, text, tag, size, styles, scripts, idx, font }) => {
  // const isNursery = block.link._type === 'nursery';

  return (
    <Link
      tabIndex={0}
      to={to}
      sx={{
        maxWidth: 'max-content',
        outline: 'none',
        justifySelf: 'center',
        alignSelf: 'center',
        position: 'relative',
        flexDirection: 'column',
        transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        backgroundColor: 'button.home_block.bg',
        textDecoration: 'none',

        '& .type': {
          transformOrigin: 'center  center',
          transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        },
        '& .subtitle': {
          textTransform: 'uppercase',
          opacity: 0.6,
          transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        },
        '&:hover, &:focus': {
          '& [data-child="tag"]': {
            transform: 'translateX(calc(50% + 20px))',
          },
          '& .type': {
            color: `accent`,
            transform: 'scale(1.1)',
          },
          '& .subtitle': {
            color: 'button.home_block.text_hover',
            opacity: 1,
          },
        },

        ':focus-visible': {
          outline: '1px solid',
          outlineColor: 'accent',
          outlineOffset: '-1px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Flex
          sx={{
            flex: 1,
            flexDirection: 'column',
            alignItems: ['start', 'center'],
            justifyContent: 'space-between',
            textAlign: ['left', 'center'],
            py: '1vmax',
          }}
        >
          <TextF5
            data-child="f5-text"
            className="type"
            font={font}
            sx={{
              fontSize: [`calc(${size}px + 1.6vmax)`],
              textAlign: ['left', 'center'],
              lineHeight: 1,
            }}
          >
            {text}
          </TextF5>
          {tag ? <Tag type={tag}>{tag}</Tag> : null}
        </Flex>
      </Box>
    </Link>
  );
};

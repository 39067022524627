import React from 'react';

export const Twitter = (props) => {
  return (
    <svg viewBox="0 0 32 27" xmlns="http://www.w3.org/2000/svg" role="presentation" {...props}>
      <title>Twitter</title>
      <path
        d="M7.944 1l2.2 2.77c1.18 1.49 2.554 2.555 4.123 3.203l.338.133.228.074.083-.367a6.158 6.158 0 012.01-3.158l.235-.188.437-.307c2.175-1.403 4.973-1.454 7.159-.144l.232.145.276.2c.288.273.526.41.712.41l1.031.02L30 3.569l-.406 1.85c-.047.214-.104.465-.17.74l-.143.578-.278 1.044c-.146.52-.29.982-.43 1.373-.192.531-.374.931-.601 1.25a.7.7 0 00-.096.425c.212 4.362-.967 8.097-3.739 10.716-3.2 3.024-7.962 3.998-12.957 3.176-1.522-.25-3.036-.87-4.345-1.582l-.351-.197L2 20.37l6.206-2.147c-1.185-1.478-2.274-3.46-2.763-5.27l-.09-.36-.109-.563c-.43-2.785.047-5.745.947-7.641l.12-.241L7.943 1zM23.89 4.832a4.79 4.79 0 00-4.94-.159l-.202.123-.372.26a4.18 4.18 0 00-1.442 1.976l-.068.227-.572 2.5-2.38-.777c-1.945-.717-3.64-1.94-5.072-3.643l-.265-.325-.281-.352-.21.407c-.795 1.53-1.19 4.047-.923 6.255l.045.328.094.496c.322 1.407 1.187 3.139 2.237 4.531l.228.293 1.773 2.213-4.733 1.637.672.385c1.243.713 2.511 1.231 3.679 1.475l.347.065c4.597.757 8.652-.193 11.259-2.655 2.247-2.124 3.306-5.235 3.114-9.166a2.7 2.7 0 01.356-1.516l.11-.168c.079-.11.172-.301.293-.62l.053-.145c.096-.266.198-.583.302-.938l.079-.274.271-1.02.12-.483-.306.023-.197.005-.99-.02c-.755-.002-1.346-.307-1.921-.813l-.052-.047-.106-.078z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Facebook = (props) => {
  return (
    <svg viewBox="0 0 32 27" xmlns="http://www.w3.org/2000/svg" role="presentation" {...props}>
      <title>Facebook</title>
      <path
        d="M26.5 1A1.5 1.5 0 0128 2.5v21a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 014 23.5v-21A1.5 1.5 0 015.5 1h21zM26 3H6v20h10.5v-6.922h-1.6a.4.4 0 01-.4-.4V13.9c0-.22.18-.4.4-.4h1.6v-3.039c0-2.048.89-2.961 2.961-2.961H21.1c.22 0 .4.18.4.4v1.95a.4.4 0 01-.4.4h-1.023c-.91 0-1.244.492-1.244 1.5v1.75l2.24.001a.398.398 0 01.364.426l-.119 1.778a.4.4 0 01-.399.373h-2.086V23H26V3z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Insta = (props) => {
  return (
    <svg viewBox="0 0 32 27" xmlns="http://www.w3.org/2000/svg" role="presentation" {...props}>
      <title>Instagram</title>
      <path
        d="M24 1a4 4 0 014 4v16a4 4 0 01-4 4H8a4 4 0 01-4-4V5a4 4 0 014-4h16zm0 2H8a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V5a2 2 0 00-2-2zm-7.98 4.612a5.4 5.4 0 110 10.8 5.4 5.4 0 010-10.8zm0 1.8a3.6 3.6 0 100 7.2 3.6 3.6 0 000-7.2zM23 5a2 2 0 110 4 2 2 0 010-4z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const LinkedIn = (props) => {
  return (
    <svg viewBox="0 0 32 27" xmlns="http://www.w3.org/2000/svg" role="presentation" {...props}>
      <title>Linkedin</title>
      <path
        d="M26.5655048,1.5 C27.3939048,1.5 28.0655048,2.17157 28.0655048,3 L28.0655048,24 C28.0655048,24.8284 27.3939048,25.5 26.5655048,25.5 L5.56550481,25.5 C4.73707481,25.5 4.06550481,24.8284 4.06550481,24 L4.06550481,3 C4.06550481,2.17157 4.73707481,1.5 5.56550481,1.5 L26.5655048,1.5 Z M26.0655048,3.5 L6.06550481,3.5 L6.06550481,23.5 L26.0655048,23.5 L26.0655048,3.5 Z M12.3319111,11.2303125 L12.3319111,20.25 L9.52644231,20.25 L9.52644231,11.2303125 L12.3319111,11.2303125 Z M19.4658173,11.0067187 C22.3008173,11.0067187 22.8281611,12.875625 22.8281611,15.305625 L22.8281611,20.25 L20.0269111,20.25 L20.0269111,15.8625 C20.0269111,14.81625 20.0058173,13.4704688 18.5714423,13.4704688 C17.1117548,13.4704688 16.8881611,14.6095312 16.8881611,15.7865625 L16.8881611,20.25 L14.0869111,20.25 L14.0869111,11.2303125 L16.7742548,11.2303125 L16.7742548,12.4621875 L16.8122236,12.4621875 C17.1876923,11.7534375 18.1031611,11.0067187 19.4658173,11.0067187 Z M10.9270673,6.75 C11.8214423,6.75 12.5512861,7.47984375 12.5512861,8.37421875 C12.5512861,9.2728125 11.8256611,9.9984375 10.9270673,9.9984375 C10.0284736,9.9984375 9.30284856,9.26859375 9.30284856,8.37421875 C9.30284856,7.47984375 10.0284736,6.75 10.9270673,6.75 Z"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </svg>
  );
};

export const Cart = (props) => {
  return (
    <svg viewBox="0 0 32 31" xmlns="http://www.w3.org/2000/svg" role="presentation" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-276-16h425v64h-425z" />
        <g stroke="currentColor" strokeWidth="2">
          <path d="M4.802 22h18l4-17h-25l2 11 20 .047M10.802 27.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM23.802 27.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM26.802 5l1-4h4" />
        </g>
      </g>
    </svg>
  );
};

export const Chevron = (props) => {
  return (
    <svg viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.00005 3L8.00005 9.00002L14.0001 3" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export const Award = (props) => {
  return (
    <svg viewBox="0 0 428 540" xmlns="http://www.w3.org/2000/svg" role="presentation" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={26}>
        <path
          d="M330.59 174.916a6.517 6.517 0 00-5.507-4.457l-74.82-7.593-30.338-68.794a6.505 6.505 0 00-5.928-3.861 6.475 6.475 0 00-5.928 3.874l-30.344 68.788-74.807 7.586a6.499 6.499 0 00-5.506 4.457 6.47 6.47 0 001.846 6.835l56.051 50.13-15.891 73.485a6.481 6.481 0 006.336 7.845c1.12 0 2.248-.292 3.258-.888l64.992-37.833 64.978 37.833a6.46 6.46 0 007.068-.382c2.073-1.483 3.07-4.1 2.533-6.608l-15.891-73.49 56.057-50.124a6.429 6.429 0 001.84-6.803z"
          strokeLinejoin="round"
        />
        <circle cx={214} cy={215.846} r={188} />
        <path
          strokeLinejoin="round"
          d="M114.39 371.746v146.656L214 473.914l97.324 44.488V371.746"
        />
      </g>
    </svg>
  );
};

export const IconLiga = (props) => {
  return (
    <svg
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      {...props}
    >
      <path
        d="M19.8235 10.5255C19.0366 10.5255 18.4601 10.7908 18.0941 11.3216C17.7281 11.834 17.5451 12.6118 17.5451 13.6549V15.1373H24.187V17.498H17.5451V29H14.4157V17.498H12V15.1373H14.4157V13.5176C14.4157 11.6144 14.8183 10.2235 15.6235 9.3451C16.4471 8.44837 17.7373 8 19.4941 8C20.519 8 21.2967 8.11895 21.8275 8.35686V10.9922C21.5346 10.8275 21.2052 10.7085 20.8392 10.6353C20.4732 10.5621 20.1346 10.5255 19.8235 10.5255ZM25.9594 29H22.7751V15.1373H25.9594V29Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconFont = (props) => {
  return (
    <svg
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      {...props}
    >
      <g transform="translate(0,0.8)">
        <path
          d="M23.5234 10V12.7473H18.3754V28.6713H15.1479V20.7093V12.7473H10V10H23.5234Z"
          fill="currentColor"
        />
      </g>
      <path
        d="M28.0137 18.2339H19.7438V20.2877H22.6651V27.6275H25.0924V20.2877H28.0137V18.2339Z"
        fill="currentColor"
      />
    </svg>
  );
};

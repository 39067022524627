/* eslint-disable */

import './src/reset.css';
import './src/global.css';

export { wrapRootElement } from './src/gatsby/wrapRootElement';
export { wrapPageElement } from './src/gatsby/wrapPageElement';

export function onRouteUpdate(location) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(('set', 'page', location.pathname + location.search));
  window.dataLayer.push({ event: 'pageview' });
}

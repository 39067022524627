export const nav = {
  position: 'relative',
  fontWeight: 'normal',
  color: 'inherit',
  textTransform: 'uppercase',
  fontFeatureSettings: '"c2sc",  "kern"',
  fontSize: [1, 1, 2, 2],
  py: [0, 6],

  textDecoration: 'none',
};

/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { motion } from 'framer-motion';
import { VisuallyHidden } from '../visually-hidden';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = React.forwardRef((props, ref) => {
  const [hover, setHover] = useState(false);
  const [mode] = useColorMode();
  return (
    <motion.button
      {...props}
      ref={ref}
      initial={false}
      animate={
        hover ? (props.open ? 'open_hover' : 'closed_hover') : props.open ? 'open' : 'closed'
      }
      aria-expanded={props.open}
      aria-controls="menu"
      className={props.className}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{
        zIndex: 999,
        padding: 0,
        color: props.open ? 'mono.50' : mode === 'dark' ? 'mono.100' : 'mono.0',
        '& hover': {
          color: 'accent',
        },
      }}
    >
      <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        role="presentation"
        sx={{ pointerEvents: 'none' }}
      >
        <Path
          variants={{
            closed: { d: 'M 2 3 L 28 3' },
            closed_hover: { d: 'M 2 1 L 28 1' },
            open: { d: 'M 2 3 L 28 21' },
            open_hover: { d: 'M 2 6 L 28 18' },
          }}
        />
        <Path
          d="M 2 12 L 28 12"
          variants={{
            closed: { opacity: 1 },
            closed_hover: { opacity: 1 },
            open: { opacity: 0 },
            open_hover: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 21 L 28 21' },
            closed_hover: { d: 'M 2 23 L 28 23' },
            open: { d: 'M 2 21 L 28 3' },
            open_hover: { d: 'M 2 18 L 28 6' },
          }}
        />
      </svg>
      <VisuallyHidden as="span">Menu</VisuallyHidden>
    </motion.button>
  );
});

/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

export const VisuallyHidden = (props) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        clip: 'rect(1px, 1px, 1px, 1px)',
        padding: '0 !important',
        border: '0 !important',
        height: ' 1px !important',
        width: ' 1px !important',
        overflow: ' hidden',
      }}
      {...props}
    />
  );
};

export const gutter = [10, 11, 12, 12, 13];

export const row = {
  paddingTop: [10, 11, 12, 12, 13],
  paddingBottom: [11, 12, 13, 13, 14],
};

export const grid = [
  {
    gridGap: [10, 11, 12, 12, 13],
  },
  {
    gridGap: [8, 9, 10],
  },
];

export const stack = [
  {
    gridGap: [10, 11, 12, 12, 13],
  },
  {
    gridGap: [8, 9, 10],
  },
];

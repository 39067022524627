/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Global } from '@emotion/core';
import { WebfontProvider } from '../../woff-provider/webfont-provider';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { GDPRPopup } from '../../components/gdpr-popup';
import { CartProvider } from '../../cart-provider';
import { motion } from 'framer-motion';

import { alreadyAcceptedPrivacyPolicy, recordPrivacyPolicyAcceptance } from '../../lib/cookies';

function RootLayout({ children }) {
  const [cookieBannerIsDisplayed, setCookieBannerIsDisplayed] = useState(
    !alreadyAcceptedPrivacyPolicy()
  );

  const onAcceptPrivacyPolicy = () => {
    setCookieBannerIsDisplayed(false);
    recordPrivacyPolicyAcceptance();
  };

  return (
    <WebfontProvider>
      <CartProvider>
        <Global
          styles={(theme) => ({
            'a:hover': {
              color: `${theme.colors.accent}`,
            },
            ':focus-visible': {
              outline: `1px solid ${theme.colors.accent}`,
              outlineOffset: '4px',
            },
          })}
        />
        <div sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          {cookieBannerIsDisplayed && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.35 }}
            >
              <GDPRPopup onAcceptPrivacyPolicy={onAcceptPrivacyPolicy} />
            </motion.div>
          )}
          <Header />
          <main sx={{ flex: 1, pt: [0, '98px', '101px'] }}>{children}</main>
          <Footer sx={{ flex: 0 }} />
        </div>
      </CartProvider>
    </WebfontProvider>
  );
}

export default RootLayout;

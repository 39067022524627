import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';

import { WebfontsContext } from './woff-context';

export const useOTF = (fontId) => {
  const { store } = useContext(WebfontsContext);

  return useObserver(() => {
    const fontLib = store.fonts[fontId] || { _id: fontId, loading: true };
    return fontLib;
  });
};
//  return useObserver(() => (store.loaded[fontId] ? store.fonts[fontId] : null));

/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';

export const NavMenu = ({ children, open, onClickOutside, onEscapeKey, shards, ...rest }) => {
  return (
    <FocusOn
      enabled={open}
      onClickOutside={onClickOutside}
      onEscapeKey={onEscapeKey}
      shards={shards}
    >
      <AnimatePresence initial={false}>
        {open ? (
          <motion.nav
            id="menu"
            {...rest}
            initial="closed"
            exit="closed"
            animate={open ? 'open' : 'closed'}
            variants={{
              open: {
                x: 0,
                transition: {
                  type: 'spring',
                  stiffness: 480,
                  damping: 50,
                },
              },
              closed: {
                x: '100%',
                transition: {
                  type: 'spring',
                  stiffness: 480,
                  damping: 50,
                },
              },
            }}
            sx={{
              position: 'fixed',
              zIndex: 998,
              top: 0,
              right: 0,
              height: '100vh',
              width: ['100%', '50%', '24em'],
              maxWidth: ['24em', 'auto'],
              bg: ['mono.0', 'mono.0'],
              borderLeft: 'line',
              borderColor: 'line',
              py: [18],
              paddingRight: [8, 11, 13],
              overflow: 'scroll',
            }}
          >
            <div aria-hidden={!open} sx={{ paddingRight: '4' }}>
              {children}
            </div>
          </motion.nav>
        ) : null}
      </AnimatePresence>
    </FocusOn>
  );
};

import { pxToRem } from './px-to-rem';

export const rhythmScale = [
  0,
  1,
  2,
  3,
  4,
  8,
  12,
  16,
  20,
  24,
  32,
  40,
  48,
  56,
  64,
  72,
  80,
  96,
  112,
  128,
];

const spaceKeys = {
  gutter: '6vmax',
};

const sizeKeys = {
  container: '100%',
};

export const space = Object.keys(rhythmScale).reduce((res, cur) => {
  res[`${cur}`] = pxToRem(rhythmScale[cur]);
  return res;
}, spaceKeys);

export const sizes = Object.keys(rhythmScale).reduce((res, cur) => {
  res[`${cur}`] = `${rhythmScale[cur] / 16}rem`;
  return res;
}, sizeKeys);

/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';

export const FontsDrawer = ({ open, onClickOutside, onEscapeKey, shards, children, ...rest }) => {
  return (
    <AnimatePresence initial={false}>
      {open ? (
        <motion.nav
          id="menu"
          {...rest}
          initial="closed"
          exit="closed"
          animate={open ? 'open' : 'closed'}
          variants={{
            open: {
              height: 'auto',
              transition: {
                type: 'spring',
                stiffness: 480,
                damping: 50,
              },
            },
            closed: {
              height: '0',
              transition: {
                type: 'spring',
                stiffness: 480,
                damping: 50,
              },
            },
          }}
          sx={{
            position: 'absolute',
            zIndex: 998,
            top: [0, '98px', '101px'],
            right: 0,
            height: 'auto',
            width: ['100%'],
            bg: 'bg.1',
            color: 'txt.0',
            borderBottom: '1px solid',
            borderColor: 'line',
            overflow: 'scroll',
          }}
        >
          <div aria-hidden={!open} sx={{ paddingRight: '4', py: [8] }}>
            {children}
          </div>
        </motion.nav>
      ) : null}
    </AnimatePresence>
  );
};

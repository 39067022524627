import { pxToRem } from './px-to-rem';

import { root } from './root';

export const typeScale = [
  14,
  16,
  18,
  20,
  21,
  25,
  28,
  33,
  38,
  44,
  51,
  58,
  67,
  78,
  90,
  104,
  120,
  138,
  160,
  185,
  213,
  246,
  284,
];
export const fonts = {
  sans: '"sans", sans-serif',
};

export const fontSizes = typeScale.map(pxToRem);

export const fontWeights = {
  body: 400,
  bold: 800,
  // black: 900,
};

export const lineHeights = {
  body: 1.6,
  heading: 1.125,
};

export const text = {
  body: root,
  copy: {
    fontSize: [2, 2, 3, 3, 4],
  },
  tiny: {
    fontSize: [0],
  },
  small: {
    fontSize: [0, 0, 1, 1, 1],
  },

  footer: {
    fontSize: [0, 0, 1, 1, 2],
  },
  large: {
    fontSize: [2, 2, 3, 4, 5],
  },
  checkbox: {
    fontSize: [1, 1, 1, 2, 2],
    lineHeight: 1.4,
    display: 'block',
  },
  h1: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [8, 10, 10, 11, 12],
    lineHeight: 1.1,
  },
  h2: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [6, 7, 8, 8, 9],
    lineHeight: 1.4,
  },
  h3: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [4, 4, 5, 5, 6],
    lineHeight: 1.4,
  },
  h4: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [2, 2, 3, 3, 3],
    lineHeight: 1.4,
  },
  h5: {
    fontWeight: 700,
    fontFamily: 'sans',
    fontSize: [1, 1, 2, 3, 4],
    lineHeight: 1.4,
  },
  h6: {
    fontWeight: 400,
    fontFamily: 'sans',
    fontSize: [1, 1, 2, 2, 2],
    lineHeight: 1.4,
  },
  nav_link: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [4],
    lineHeight: 1.1,
    textDecoration: 'none',
  },
  section_header: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [2, 2, 3, 3, 3],
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },

  small_header: {
    fontWeight: 'bold',
    fontFamily: 'sans',
    fontSize: [1, 1, 2, 2, 2],
    lineHeight: 1.4,
  },

  f5_hero_copy: {
    fontWeight: 'bold',
    fontSize: [
      'calc(18px + 3vmin)',
      'calc(20px + 2vmax)',
      'calc(20px + 2vmax)',
      // 'calc(32px + 12vw)',
      // 'calc(32px + 12vw)',
    ],
    lineHeight: 1.2,
  },
  f5_typeface_tagline: {
    fontSize: [
      'calc(12px + 8vmin)',
      'calc(20px + 8vmax)',
      'calc(20px + 8vmax)',
      // 'calc(32px + 12vw)',
      // 'calc(32px + 12vw)',
    ],
    lineHeight: 1.1,
  },
  f5_typeface_feature: {
    fontSize: [
      'calc(20px + 2vw)',
      'calc(12px + 2vmax)',
      'calc(14px + 2vmax)',
      'calc(16px + 2vmax)',
      // 'calc(32px + 12vw)',
    ],
    lineHeight: 1.1,
  },
  f5_typeface_product: {
    fontSize: [
      'calc(14px + 1.6vmin)',
      'calc(12px + 1.6vmax)',
      'calc(12px + 1.6vmax)',
      'calc(14px + 1.6vmax)',
      // 'calc(32px + 12vw)',
    ],
    lineHeight: 1.1,
  },

  f5_typeface_product_single: {
    fontSize: ['calc(12px + 1.3vmax)', 'calc(12px + 1.3vmax)', 'calc(12px + 1vmax)'],
    lineHeight: 1.1,
  },

  f5_landing_block_font: {
    maxWidth: '15ch',
    display: 'block',
    fontSize: [
      'calc(24px + 10vmin)', //
      'calc(20px + 9vmax)',
      'calc(20px + 9vmax)',
    ],
    lineHeight: 1.1,
  },
  f5_landing_block_font_sm: {
    display: 'block',
    maxWidth: '15ch',
    fontSize: [
      'calc(20px + 5vmin)', //
      'calc(20px + 5vmax)',
      'calc(20px + 5vmax)',
    ],
    lineHeight: 1.1,
  },
};

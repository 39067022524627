/** @jsx jsx */
import { useMemo } from 'react';
import { jsx, Text } from 'theme-ui';
import { observer } from 'mobx-react';
import { useOTF } from '../../woff-provider/use-otf';
import { useFontLoader } from '../../woff-provider/use-font-loader';

export const TextF5 = observer(
  ({ variant, font, features = [], children, className = '', ...rest } = { features: [] }) => {
    let otf = useOTF(font);
    useFontLoader(font);

    const fontFeatureSettings = useMemo(() => {
      return features
        .reduce((res, f) => {
          res.push(`"${f.key}" 1`);
          return res;
        }, [])
        .join(', ');
    }, [features]);

    return (
      <Text
        {...rest}
        variant={variant}
        className={`${className} txt-${otf._id.trim().replace(/-/g, '')}`}
        sx={{
          opacity: 0,
          fontFamily: `"${otf._id}"`,
          fontFeatureSettings: fontFeatureSettings,
        }}
      >
        {children}
      </Text>
    );
  }
);

export default TextF5;

/** @jsx jsx */
import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { jsx, Box, Container, Flex, Text, useColorMode } from 'theme-ui';
import { globalHistory } from '@reach/router';
import Helmet from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';
import useMedia from 'use-media';
import { VisuallyHidden } from '../visually-hidden';

import { Logo } from '../logo';
import { Row, Stack } from '../grid';
import { Cart } from '../../icons';
import { Tally } from '../typeface-shop/tally';

import { FontBlock } from './font-block';
import { FontsDrawer } from './fonts-drawer';
import { NavMenu } from './nav-menu';
import { MenuToggle } from './menu-toggle';
import { ThemeSwitch } from './theme-switch';
import { useCart } from '../../cart-provider';
import TextF5 from '../text-f5';

const NavLink = ({ to, children, ...rest }) => (
  <Link to={to} sx={{ display: 'block', variant: 'buttons.header_nav_link' }} {...rest}>
    {children}
  </Link>
);

export const Header = (props) => {
  const { settings } = useStaticQuery(settingsQuery);
  const isSmall = useMedia({ maxWidth: '47.5rem' });
  const [colorMode, setColorMode] = useColorMode();

  const [isNavOpen, setNavOpen] = useState(false);

  const [activeDrawer, setActiveDrawer] = useState(false);
  const fontsButtonRef = useRef();
  const { cart } = useCart();

  const toggleRef = useRef();

  const handleDrawerLinkClick = (e, drawer) => {
    e.preventDefault();
    fontsButtonRef.current = e.target;
    setActiveDrawer(drawer);
  };

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const hideNav = () => {
    setNavOpen(false);
  };

  const hideDrawer = () => {
    setActiveDrawer(null);
  };

  useEffect(() => {
    const historyUnsubscribe = globalHistory.listen(hideNav);

    return () => {
      historyUnsubscribe();
    };
  });

  useEffect(() => {
    if (!isSmall) {
      setNavOpen(false);
    } else {
      hideDrawer();
    }
  }, [isSmall]);

  useEffect(() => {
    const main = document.getElementsByTagName('main')[0];
    if (!activeDrawer) {
      document.body.style.overflow = 'unset';
      main.style.filter = 'blur(0px)';
      main.style.opacity = 1;
      return;
    }

    const onDocumentClick = () => {
      setActiveDrawer(null);
    };

    document.body.style.overflow = 'hidden';

    main.style.filter = 'blur(16px)';
    main.style.opacity = 0.2; // 0.2 is the opacity value;
    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [activeDrawer, hideNav]);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: colorMode == 'dark' ? 'dark' : 'default',
        }}
      />
      <Container
        as="header"
        sx={{
          position: ['relative', 'fixed'],
          top: 0,
          width: '100%',
          bg: 'bg.1',
          borderBottom: '1px solid',
          borderColor: 'line',
          flex: 0,
          py: [8, 10],
          zIndex: 999,
        }}
      >
        <Flex sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Link
            to="/"
            sx={{
              variant: 'buttons.link',
              width: '100%',
              maxWidth: ['5.5rem', '6.5rem', '15.5rem'],
              display: 'flex',
              position: 'relative',
            }}
          >
            <Logo sx={{ display: 'block', width: '100%', height: 'auto' }} />
            <VisuallyHidden as="span">Foundry 5</VisuallyHidden>
          </Link>

          <Flex sx={{ alignItems: 'center ' }}>
            <Flex sx={{ gap: [5], mr: [10], display: ['none', 'flex'] }}>
              <NavLink
                sx={{ mr: [5] }}
                to="/"
                onClick={(e) => {
                  handleDrawerLinkClick(e, 'typefaces');
                }}
              >
                Typefaces
              </NavLink>

              <NavLink sx={{ mr: [5] }} to="/type/trial-fonts">
                Trial Fonts
              </NavLink>
              <NavLink sx={{ mr: [5] }} to="/about-us">
                About us
              </NavLink>
              <NavLink sx={{ mr: [5] }} to="/licencing">
                Licencing
              </NavLink>
              <NavLink to="/cart" sx={{ pr: 8 }}>
                Cart
                <Tally
                  tally={Object.keys(cart).length}
                  sx={{ right: 0, marginLeft: '-20px', marginTop: '-10px' }}
                />
              </NavLink>
            </Flex>

            <Flex className="snipcart-summary" sx={{ display: ['block', 'none'], mr: [8, 10, 11] }}>
              <Link
                to="/cart"
                sx={{
                  position: 'relative',
                  textDecoration: 'none',
                  alignItems: 'center',
                  transition: 'all 0.2s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
                }}
              >
                <Cart sx={{ width: 10 }} />
                <VisuallyHidden as="span">Cart</VisuallyHidden>

                <Tally
                  tally={Object.keys(cart).length}
                  sx={{ right: '100%', left: 0, marginLeft: '-10px', marginTop: '-10px' }}
                />
              </Link>
            </Flex>

            <ThemeSwitch sx={{ mr: [8, 0, 0] }} />
            {isSmall && (
              <MenuToggle id="toggle" ref={toggleRef} onClick={toggleNav} open={isNavOpen} />
            )}
          </Flex>
          {!isSmall && (
            <FontsDrawer open={activeDrawer} onEscapeKey={hideDrawer} shards={[fontsButtonRef]}>
              <Box
                sx={{
                  display: 'grid',
                  gap: '1vmax',
                  justifyContent: 'center',
                  alignItems: 'center', // center the last row

                  ['@media (min-width: 47.5rem)']: {
                    gridTemplateColumns: `1fr 1fr`,
                  },
                  ['@media (min-width: 57.5rem)']: {
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  },
                  ['@media (min-width: 117.5rem)']: {
                    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                  },
                }}
              >
                {settings.typeface_fonts.map((t) => (
                  <FontBlock
                    scripts={t.link.info.scripts}
                    styles={t.link.info.styles}
                    tag={t.tag}
                    size={t.size}
                    font={t.font._id}
                    text={t.sample}
                    to={`/${t.link._type === 'typeface' ? 'type' : 'nursery'}/${
                      t.link.slug.current
                    }`}
                  />
                ))}
              </Box>
            </FontsDrawer>
          )}
        </Flex>
        {isSmall && (
          <NavMenu
            open={isNavOpen}
            onClickOutside={hideNav}
            onEscapeKey={hideNav}
            shards={[toggleRef]}
          >
            <Stack
              as="ul"
              gap={[7]}
              role="menu"
              sx={{
                position: 'relative',
                listStyle: 'none',
                textAlign: 'right',
                paddingBottom: [10, 14],
                marginBottom: [10, 14],
                '&:after': {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  content: "''",
                  width: [16],
                  backgroundColor: 'line',
                  height: '1',
                },
              }}
            >
              <Stack>
                <h3 sx={{ color: 'mono.60', fontSize: 1 }}>Typefaces</h3>
                {settings.nav_fonts.map((t) => (
                  <li role="none" key={t.slug.current}>
                    <Link
                      to={`/type/${t.slug.current}`}
                      sx={{ display: 'inline-block', variant: 'buttons.nav_link' }}
                    >
                      <Text variant="nav_link">{t.title}</Text>
                    </Link>
                  </li>
                ))}
              </Stack>
              <Stack>
                <h3 sx={{ color: 'mono.60', fontSize: 1 }}>Nursery</h3>
                {settings.nav_fonts_nursery.map((t) => (
                  <li role="none" key={t.slug.current}>
                    <Link
                      to={`/nursery/${t.slug.current}`}
                      sx={{ display: 'inline-block', variant: 'buttons.nav_link' }}
                    >
                      <Text variant="nav_link">{t.title}</Text>
                    </Link>
                  </li>
                ))}
              </Stack>

              <Stack>
                <h3 sx={{ color: 'mono.60', fontSize: 1 }}>Trial</h3>
                <li>
                  <Link
                    to={`/type/trial-fonts`}
                    sx={{ display: 'inline-block', variant: 'buttons.nav_link' }}
                  >
                    <Text variant="nav_link">F5 Trial Fonts</Text>
                  </Link>
                </li>
              </Stack>
            </Stack>

            <Stack
              as="ul"
              gap={[7]}
              role="menu"
              sx={{ listStyle: 'none', textAlign: 'right', mt: '-10px' }}
            >
              {settings.side_nav_pages.map((p) => (
                <li role="none" key={p.slug.current}>
                  <Link
                    to={`/type/${p.slug.current}`}
                    sx={{ display: 'inline-block', variant: 'buttons.nav_link' }}
                  >
                    <Text variant="nav_link">{p.title}</Text>
                  </Link>
                </li>
              ))}
            </Stack>
          </NavMenu>
        )}
      </Container>
    </>
  );
};

const settingsQuery = graphql`
  query {
    settings: sanitySiteSettings {
      _id
      typeface_fonts {
        sample
        tag
        size
        font {
          _id
        }
        link {
          ... on SanityTypeface {
            id
            _type
            slug {
              current
            }
            info {
              scripts
              styles
            }
          }
          ... on SanityNursery {
            id
            _type
            slug {
              current
            }
            info {
              styles
              scripts
            }
          }
        }
      }
      side_nav_pages {
        ... on SanityPageAbout {
          id
          title
          slug {
            current
          }
        }
        ... on SanityPage {
          id
          title
          slug {
            current
          }
        }
      }
      nav_fonts_nursery {
        id
        title
        slug {
          current
        }
      }
      nav_fonts {
        ... on SanityTypeface {
          id
          title
          published
          slug {
            current
          }
        }
        ... on SanityPageTrials {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

export default Header;
